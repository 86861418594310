<template lang="pug">
  div.wrap-module-calendar
    div.wrap-client-selector.f.fh
      ItemAutoCompleteSelector(
        ref="clientSelector"
        v-if="clients"
        :allUsers="clients"
        placeholder="会員様を選択"
        icon="mdi-account-box-multiple"
        @callBackOnList="callBackOnList")
    div.wrap-calendar-header.f.fm
      div(v-if="type === 'month'").header-content.f.fm.flex-between
        v-icon(@click="prev") mdi-chevron-left
        span.pt1 {{headerLabel}}
        v-icon(@click="next") mdi-chevron-right
      div(v-if="type === 'day'").header-content.f.fm
        v-icon(@click="type = 'month'") mdi-chevron-left
        span.ml4.pt1 {{headerDayLabel}}
    div.wrap-calendar
      v-calendar(
        ref="calendar"
        v-model="value"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @change='onChangeEvents'
        @click:date="clickDate"
        @click:interval="clickEvent")
      div(@click="() => { $emit('openModal') }").button-add-event.f.fh.px12.py12
        v-icon(color='#fff') add

</template>

<style lang="scss" scoped>
.wrap-module-calendar {
  position: relative;
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 48px;
  .wrap-client-selector {
    height: 80px;
    border-bottom: solid #999 0.8px;
  }
  .wrap-calendar-header {
    height: 36px;
    .header-content {
      width: 93%;
      max-width: 820px;
      margin: 0 auto;
    }
  }
  .wrap-calendar {
    position: relative;
    height: calc(100vh - 48px * 2 - 36px);
    .button-add-event {
      z-index: 101;
      position: absolute;
      right: 12px;
      bottom: 42px;
      cursor: pointer;
      border-radius: 50%;
      background: #1a73e8;
      filter: drop-shadow(1px 1px 1px rgba(0, 0, 0, 0.4));
    }
  }
}
</style>

<style lang="scss">
.wrap-module-calendar {
  .v-btn__content {
    color: #2a2a2a;
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import format from 'date-fns/format'
import { createNamespacedHelpers } from 'vuex'
import ItemAutoCompleteSelector from '@/components/item/ItemAutoCompleteSelector'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    ItemAutoCompleteSelector
  },
  props: {
  },
  data () {
    return {
      clients: [],

      events: [],
      allEvents: [],
      headerLabel: '',
      headerDayLabel: '',
      now: new Date(),
      type: 'month',
      types: ['month', 'week', 'day', '4day'],
      mode: 'stack',
      modes: ['stack', 'column'],
      weekday: [0, 1, 2, 3, 4, 5, 6],
      weekdays: [
        { text: 'Sun - Sat', value: [0, 1, 2, 3, 4, 5, 6] },
        { text: 'Mon - Sun', value: [1, 2, 3, 4, 5, 6, 0] },
        { text: 'Mon - Fri', value: [1, 2, 3, 4, 5] },
        { text: 'Mon, Wed, Fri', value: [1, 3, 5] }
      ],
      value: '',
      colors: ['blue', 'indigo', 'deep-purple', 'cyan', 'green', 'orange', 'grey darken-1']
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  async created () {
    var allClients = await db.collection('CLIENTS')
      .get()
      .then(q => {
        return q.docs.map(d => {
          return d.data()
        }).filter(client => {
          return client.authedClientUid
        })
      })

    this.clients = [{
      name: '全て',
      authedClientUid: 'all_clients',
      photoURL: '/img/default-user-icon.png'
    }].concat(allClients)

    db.collection('EVENTS')
      .onSnapshot(this.initEvent)
  },
  mounted () {
    // schedulerlyClient.authenticate({
    //   onTokenRequired: () => {
    //     // Call the endpoint created on step 2
    //     return generateSchedulerlyToken()
    //       .then(({ data }) => data.token)
    //   }
    // }).then((e) => {
    //   // Do other stuffs with schedulerlyClient
    // })
  },
  methods: {
    initEvent (q) {
      var events = q.docs.map(d => {
        var data = d.data()
        data.id = d.id
        return data
      })
      this.allEvents = events.map(e => {
        if (e.allDay) {
          return {
            id: e.id,
            name: e.title,
            color: 'blue',
            start: new Date(e.date),
            end: new Date(e.date),
            timed: false,
            authedClientUid: e.authedClientUid,
            createdUserType: e.createdUserType
          }
        } else {
          return {
            id: e.id,
            name: e.title,
            color: 'blue',
            start: new Date(`${e.date}T${e.startHour}:${e.startMinute}:00`),
            end: new Date(`${e.date}T${e.endHour}:${e.endMinute}:00`),
            timed: true,
            authedClientUid: e.authedClientUid,
            createdUserType: e.createdUserType
          }
        }
      })
      this.allEvents = this.allEvents.map(event => {
        event.color = (event.createdUserType === 'trainer') ? 'orange' : 'blue'
        return event
      })
      this.events = this.allEvents
    },
    onChangeEvents ({ start, end }) {
      this.headerLabel = format(new Date(start.date), 'M月')
    },
    clickDate (event) {
      if (this.type === 'month') {
        this.type = 'day'
        this.headerDayLabel = format(new Date(event.date), 'yyyy年M月d日')
      }
    },
    clickEvent (event) {
    },
    prev () {
      this.$refs.calendar.prev()
    },
    next () {
      this.$refs.calendar.next()
    },
    getEventColor (event) {
      return event.color
    },
    callBackOnList () {
      var selectedUser = this.$refs.clientSelector.selectedUser
      if (selectedUser.authedClientUid === 'all_clients') {
        this.$router.push('/calendar')
        this.events = this.allEvents
        return true
      } else {
        this.events = this.allEvents.filter(event => {
          return (event.authedClientUid === selectedUser.authedClientUid)
        })
        this.$router.push(`/calendar/${selectedUser.authedClientUid}`)
      }
    }
  }
}
</script>
