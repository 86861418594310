<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    Header
    div.wrap-calendar
      ModuleCalendar(v-if="uid" @openModal="() => { showModalWindow = true }")
    ModalWindow(
      @closeModal="closeModalWindow"
      modalContentId="addCalendarEvent"
      :showModal="showModalWindow")
</template>

<style lang="scss" scoped>
.wrap-calendar {
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import Header from '@/components/shared/Header'
import ModalWindow from '@/components/shared/Modal'
import ModuleCalendar from '@/components/module/ModuleCalendar'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    Header,
    ModalWindow,
    ModuleCalendar
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  data () {
    return {
      showModalWindow: false
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication () {
      this.$router.push('/sign-in')
    },
    closeModalWindow () {
      this.showModalWindow = false
    }
  }
}
</script>
